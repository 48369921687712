<template>
    <div
        class="sq-sfdb my-4 text-label-1"
        ref="question_title_ref"
    >
        {{ question.title }}<span class="sq-sfdb__asterisk">{{ is_required ? "*" : "" }}</span>
    </div>
    <RisifyRadio
        outlined
        class-name="mb-2"
        variant="tile"
        :true-value="SurveySfdbQuestionAnswerType.AFFIRMATIVE"
        v-model="question_sfdb_value"
        :label="question.sfdb.answer_affirmative_label"
        @update:model-value="validate"
    >
    </RisifyRadio>
    <RisifyRadio
        outlined
        class-name="mb-2"
        variant="tile"
        :true-value="SurveySfdbQuestionAnswerType.NEGATIVE"
        v-model="question_sfdb_value"
        :label="question.sfdb.answer_negative_label"
        @update:model-value="validate"
    >
    </RisifyRadio>
    <div
        v-if="question_sfdb_value"
        class="mt-4"
    >
        <RisifyTextarea
            v-if="question_sfdb_value === SurveySfdbQuestionAnswerType.AFFIRMATIVE"
            :label="question.sfdb.answer_affirmative_description_label"
            v-model="question_sfdb_affirmative_description_value"
            class-name="mb-1"
            outlined
            :min-length="question.sfdb.answer_affirmative_description_required ? 1 : 0"
            :max-length="description_max_length"
            :rows="3"
            autogrow
            :counter="description_max_length"
            @update:model-value="validate"
            :show-asterisk="question.sfdb.answer_affirmative_description_required"
        >
        </RisifyTextarea>
        <RisifyTextarea
            v-else-if="question_sfdb_value === SurveySfdbQuestionAnswerType.NEGATIVE"
            :label="question.sfdb.answer_negative_description_label"
            v-model="question_sfdb_negative_description_value"
            class-name="mb-1"
            outlined
            :min-length="question.sfdb.answer_negative_description_required ? 1 : 0"
            :max-length="description_max_length"
            :rows="3"
            autogrow
            :counter="description_max_length"
            @update:model-value="validate"
            :show-asterisk="question.sfdb.answer_negative_description_required"
        >
        </RisifyTextarea>
    </div>
    <p class="sq-sfdb__errmsg">{{ question_error }}</p>
</template>
<script setup lang="ts">
import { ref, computed } from "vue";
import RisifyRadio from "@/components/form-inputs/RisifyRadio.vue";
import RisifyTextarea from "@/components/form-inputs/RisifyTextarea.vue";
import {
    SurveyQuestionSfdb,
    SurveySfdbQuestionAnswerType,
    SurveyQuestionExpose,
    SurveyQuestionValue
} from "@/types/surveys";

const props = defineProps<{
    question: SurveyQuestionSfdb;
}>();

defineExpose<SurveyQuestionExpose>({
    getValue,
    validate,
    getAnchorElement: () => question_title_ref.value
});

const question_sfdb_value = ref<string | null>(null);
const question_sfdb_affirmative_description_value = ref<string>("");
const question_sfdb_negative_description_value = ref<string>("");

const question_error = ref<string>("");
const question_title_ref = ref<HTMLElement>();
const description_max_length = ref<number>(255);
const is_required = computed(() => {
    return props.question.sfdb.required;
});

function getValue(): SurveyQuestionValue | null {
    if (question_sfdb_value.value === null) return null;

    const values = [question_sfdb_value.value];

    if (
        question_sfdb_value.value === SurveySfdbQuestionAnswerType.AFFIRMATIVE &&
        question_sfdb_affirmative_description_value.value
    ) {
        values.push(question_sfdb_affirmative_description_value.value);
    }

    if (
        question_sfdb_value.value === SurveySfdbQuestionAnswerType.NEGATIVE &&
        question_sfdb_negative_description_value.value
    ) {
        values.push(question_sfdb_negative_description_value.value);
    }

    return { question: props.question._id, values };
}

function validate(): boolean {
    if (is_required.value && question_sfdb_value.value === null) {
        question_error.value = "Odpowiedź jest wymagana";
        return false;
    }

    if (question_sfdb_value.value === SurveySfdbQuestionAnswerType.AFFIRMATIVE) {
        if (
            props.question.sfdb.answer_affirmative_description_required &&
            (!question_sfdb_affirmative_description_value.value || question_sfdb_affirmative_description_value.value.length < 1)
        ) {
            question_error.value = "Opis jest wymagany";
            return false;
        } else if (
            question_sfdb_affirmative_description_value.value.length > description_max_length.value
        ) {
            question_error.value = `Opis może zawierać maksymalnie ${description_max_length.value} znaków`;
            return false;
        }
    } else if (question_sfdb_value.value === SurveySfdbQuestionAnswerType.NEGATIVE) {
        if (
            props.question.sfdb.answer_negative_description_required &&
            (!question_sfdb_negative_description_value.value || question_sfdb_negative_description_value.value.length < 1)
        ) {
            question_error.value = "Opis jest wymagany";
            return false;
        } else if (
            question_sfdb_negative_description_value.value.length > description_max_length.value
        ) {
            question_error.value = `Opis może zawierać maksymalnie ${description_max_length.value} znaków`;
            return false;
        }
    }
     
    question_error.value = "";
    return true;
}
</script>
